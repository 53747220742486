import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/fuel/1.jpg'

const FuelEquiptment = (props) => (
    <Layout>
        <Helmet>
            <title>Fuel Equiptment</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Fuel Equiptment</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src={pic01} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <p>Marcon Tanks is one of Ireland's leading distributors of fuel dispensing equipment including fuel pumps, fuel management systems, flow meters, hoses and dispensing nozzles. We supply a wide selection of products including many major brands.

                            Fuel storage and handling is an important part of many businesses activities and the correct fuel dispensing solution can be critical. We pride ourselves on providing fuel dispensing equipment that is reliable, precise and cost-effective and is ideal for many different businesses requirements.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default FuelEquiptment